import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useIsAuthenticated from './useIsAuthenticated';

/**
 * Redirects the user to the page set in the ?from= param when they are
 * logged in as the given user type.
 * Should probably be used on all Login pages.
 */
const useRedirectOnAuthenticated = (
  userType: 'Customer' | 'PartnerAgent' | 'Operator',
  defaultRedirect: string,
) => {
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();

  const isAuthenticatedAsUserType = isAuthenticated[`as${userType}`];

  useEffect(() => {
    // Redirect back to where the user came from
    // Works icm w/ serverAuthenticate: adds ?from=/current-route if not logged in
    if (isAuthenticatedAsUserType) {
      void router.push((router.query.from as string) || defaultRedirect);
    }
  }, [defaultRedirect, isAuthenticatedAsUserType, router]);
};

export default useRedirectOnAuthenticated;

import { Box, Container, Flex, Icon } from '@energiebespaarders/symbols';
import { Cancel } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';
import config from '../../config';
import { shade, themify } from '../../styles/mixins';
import HybridLink from '../HybridLink';

const StyledBanner = styled.div<{ $bgColor: string; $color: string; $fontSize: number }>`
  background: ${x => x.$bgColor};
  font-size: ${x => x.theme.type.scale[x.$fontSize]};
  font-weight: 500;
  color: ${x => x.$color};

  & a {
    color: white;

    &:hover {
      color: ${x => shade(0.66, x.$bgColor)};
    }
  }
`;

interface TopBannerProps {
  bannerText: string | JSX.Element;
  bgColor?: string;
  color?: string;
  cookieName?: string;
  expiry?: string;
  fontSize?: number;
  href?: string;
  onClick?: () => void;
  onlyVisibleOn?: string[];
  scrollAnchor?: string;
  scrollDuration?: number;
  scrollOffset?: number;
  to?: string;
}

export const TopBanner: React.FC<TopBannerProps> = ({
  bannerText,
  bgColor = 'orange',
  color = 'white',
  cookieName,
  expiry = '2099-12-31',
  fontSize = 6,
  href,
  onClick,
  onlyVisibleOn,
  scrollAnchor,
  scrollDuration = 300,
  scrollOffset = 0,
  to,
}) => {
  const { asPath } = useRouter();
  const [cookies, setCookie] = useCookies([cookieName || 'hideBanner']);
  const [hideBanner, setHideBanner] = useState(false);
  const msLeft = dayjs(expiry).diff(dayjs(), 'ms');

  /** Check if the banner should be visible on the current page
   * If onlyVisibleOn is not set, the banner is visible on all pages
   * If onlyVisibleOn is set, the banner is only visible on the pages in the array
   * If a path in the array ends with a *, the banner is visible on all pages that start with that path
   */
  const isOnCorrectPage = onlyVisibleOn?.length
    ? onlyVisibleOn.some(path =>
        path.endsWith('*') ? asPath.includes(path.replaceAll('*', '')) : asPath === path,
      )
    : true;
  const hasClosedCookie = cookieName && cookies[cookieName] === 'true';
  const bannerHidden =
    // don't know why, but this causes a weird full screen Spinner when rendered on the server, so I'm disabling it
    typeof window === 'undefined' ||
    msLeft <= 0 ||
    hideBanner ||
    hasClosedCookie ||
    !isOnCorrectPage;

  return bannerHidden ? (
    <></>
  ) : (
    <StyledBanner $bgColor={themify(bgColor)} $color={themify(color)} $fontSize={fontSize}>
      <Flex justifyContent="space-between" p={2}>
        <Box width="calc(100% - 18px)" pr={2}>
          <Container size="lg" textAlign="center">
            {scrollAnchor ? (
              <ScrollLink
                to={scrollAnchor}
                spy
                smooth
                isDynamic
                duration={scrollDuration}
                offset={scrollOffset}
              >
                {bannerText}
              </ScrollLink>
            ) : href ? (
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                title={typeof bannerText === 'string' ? bannerText : ''}
              >
                {bannerText}
              </a>
            ) : to ? (
              <HybridLink to={to} style={{ color: 'unset' }}>
                {bannerText}
              </HybridLink>
            ) : (
              <div onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}>
                {bannerText}
              </div>
            )}
          </Container>
        </Box>
        {cookieName && (
          <Box width="18px" textAlign="right">
            <Icon
              icon={Cancel}
              solid
              width="18px"
              fill="white"
              hoverColor={shade(0.66, themify(bgColor))}
              onClick={() => {
                setHideBanner(true);
                setCookie(cookieName, true, {
                  path: '/',
                  expires: dayjs().add(1, 'year').toDate(),
                  maxAge: 31536000, // 1 year
                  secure: config.isProduction || config.isStaging,
                });
              }}
            />
          </Box>
        )}
      </Flex>
    </StyledBanner>
  );
};

export default TopBanner;

import { Button, Container } from '@energiebespaarders/symbols';
import Head from 'next/head';
import { useRouter } from 'next/router';
import AuthenticationForms from '~/components/AuthenticationForms';
import ErrorBoundary from '~/components/ErrorBoundary';
import TopBanner from '~/components/_banners/TopBanner';
import useIsAuthenticated from '~/hooks/useIsAuthenticated';
import useRedirectOnAuthenticated from '~/hooks/useRedirectOnAuthenticated';

export const Login = () => {
  useRedirectOnAuthenticated('Customer', '/mijn-verduurzaming');
  const isAuthenticated = useIsAuthenticated();
  const { query } = useRouter();

  const { magicLinkExpired, externalAccount, error } = query;
  const bannerText =
    magicLinkExpired === 'true'
      ? 'De link waarmee u probeert in te loggen is verlopen. Vraag hieronder een nieuwe aan.'
      : externalAccount === 'true'
      ? 'Uw aanvraag bij ons is via een externe partij gedaan. Er is daarom geen account bij ons voor u geactiveerd. Neem contact met hen op om door te gaan.'
      : error === 'true'
      ? 'Er is iets misgegaan bij het inloggen. Probeer het opnieuw of neem contact met ons op.'
      : '';

  return (
    <ErrorBoundary>
      <Head>
        <link rel="canonical" href={'https://energiebespaarders.nl/login'} key="meta-canonical" />
      </Head>

      {bannerText && <TopBanner bgColor="red" bannerText={bannerText} />}

      <Container size="md" py={10}>
        {isAuthenticated.asCustomer ? (
          <div className="text-center">
            <h1>Welkom terug!</h1>
            <div className="deb-divider" />
            <div className="h5">U bent succesvol ingelogd</div>
            <br />
            <Button to="/mijn-verduurzaming" inverse label="Ga naar uw account →" />
          </div>
        ) : (
          <AuthenticationForms defaultForm="login" />
        )}
      </Container>
    </ErrorBoundary>
  );
};

export default Login;
